<template>
  <RulesStatus
    class="mx-auto"
    status-icon="$mdi-clock-outline"
    title="Coming Soon"
  />
</template>

<script>
import RulesStatus from './RulesStatus'

export default {
  name: 'ComingSoon',
  components: {
    RulesStatus,
  },
}
</script>
