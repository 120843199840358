<template>
  <div class="text-center ap-dark-gray--text">
    <v-icon x-large>{{ statusIcon }}</v-icon>
    <div class="text-h6 font-weight-medium mt-2">
      {{ title }}
    </div>
    <div v-if="description" class="text-body-2">
      {{ description }}
    </div>
    <v-btn
      v-if="buttonLabel"
      outlined
      small
      class="mt-3"
      color="primary"
      @click="$emit('click')"
    >
      <v-icon left>{{ buttonIcon }}</v-icon>
      {{ buttonLabel }}
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    statusIcon: {
      type: String,
      default: undefined,
    },
    title: {
      type: String,
      default: undefined,
    },
    description: {
      type: String,
      default: undefined,
    },
    buttonLabel: {
      type: String,
      default: undefined,
    },
    buttonIcon: {
      type: String,
      default: undefined,
    },
  },
}
</script>
