<template>
  <div>
    <div class="d-flex pa-4">
      <h2 class="text-h6 font-weight-black">Governance Rules</h2>
    </div>
    <ComingSoon />
  </div>
</template>

<script>
import ComingSoon from './components/business-rules/ComingSoon'

export default {
  name: 'AdminGovernanceRules',
  components: {
    ComingSoon,
  },
}
</script>
